const config = {
  breadcrumbNameMap: {
    '': 'Dashboard',
    '/': 'Dashboard',
    '/dashboard': 'Dashboard',
    '/customers': 'Customers',
    '/adduser': 'Add User',
    '/banks': 'Banks',
    '/addBank': 'Banks',
    '/transaction': 'Transactions',
    '/buysell': 'Buy / Sell',
    '/swap': 'Swap',
    '/deposit': 'Deposit',
    '/fiatDetails': 'Deposit',
    '/withdraw': 'Withdraw',
    '/withdrawfait': 'withdrawfait',
    '/auditlogs': 'Audit Logs',
    '/Reports': 'Reports',
    '/uploadmasspayments': 'Upload MassPayments',
    '/payments': 'Bill Payments',
    '/memberdepositcrypto': 'Member Deposit Crypto',
    '/alerts': 'Alerts',
     '/cases': 'Cases',
    '/kompany': 'Kompany',
    '/reconcile': 'Reconcile',
    '/reconciledetails': 'Reconcile Details',
    'reconciletransactions':'Reconcile Transactions',
    '/balances':'Balances',
    '/companywallets':"Company Wallets",
    '/userprofile':"Manage Your Account",
    '/internalWalletTransfer': "Internal Wallet Transfer",
    '/transactionscompliance':"Transactions Compliance",
    '/referral':"Referral",
    '/bonus':"Bonus",
    '/pyrroscustomer': "Customer",
    '/evolvecustomer': "Customer",
    '/pyrrosdeposit': "Receive",
    '/evolvedeposit': "Deposit",
    '/pyrrostransaction': "Transactions",
    '/evolvetransaction': "Transactions",
    '/pyrroswithdrawal': "Send",
    '/evolvewithdrawal': "Withdrawal",
    '/batchPayments':"Batch Payments",
    '/personalbanks':"Personal Banks",
    '/openpaydcustomer':"Customer",
    '/openpayddeposit':"Deposit",
    '/openpaydwithdrawal':"Withdrawal",
    '/openpaydtransaction':"Transactions",
    '/deductions':"Deductions",
    '/card':"Cards",
    '/openpaydReports':"OpenPayd Reports",


  },
  dateFormates:{
    "dateFormate":"DD/MM/YYYY",
    "dateTimeFormate":"DD/MM/YYYY hh:mm:ss A"
  },
}
export default config;