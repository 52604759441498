import React,{Component} from "react";
import List from "../../grid.component";
import {Modal,Form,Tooltip,Row,	Col,Select,	Button,	message,Alert,Input} from "antd";
import { validateContentRule } from "../../../utils/custom.validator";
import { getCustomerState,saveCustomerState } from "../api";
import { connect } from "react-redux";
import apiCalls from "../../../api/apiCalls";
import Loader from "../../loader.component";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { setBreadcrumb,clearBreadcrumb } from "../../../reducers/breadcrumbReducer";
const { TextArea } = Input;
class DeductionGrid extends Component{
    constructor(props) {    
        super(props);
        this.state = {
          selection: [],
          selectedObj: {},
          gridUrl:process.env.REACT_APP_GRID_API + "Withdraw/Deductions/Transactions",
          modal:false,
          getstatesData:[],
          errorMsg:null,
          gridError:null,
          selectedState:null,
          stateLoading:false
        };
         this.formref = React.createRef();
         this.gridRef = React.createRef();
      }
      
	gridColumns = [
		{
			field: "",
			title: "",
			width: 50,
			locked: true,
			customCell: (props) => (

				<label className="text-center custom-checkbox">
					<input
						id={props.dataItem.id}
						name="check"
						type="checkbox"
						checked={this.state.selection.indexOf(props.dataItem.id) > -1}
						onChange={(e) => this.handleSelectionChange(props, e)}
					/>
					<span></span>{" "}
				</label>
			)
		},
		{
			field: "deductionTransactionId",
			title: "Transaction ID",
			filter: true,
			locked: true,
			width: 210,
			customCell: (props) => (
				<div
					className="gridLink"
					onClick={() => this.deductionsDetailView(props)}
				>
					{props.dataItem?.deductionTransactionId}
				</div>
			),
		},
		{
			field: "submissionDate",
			title: "Submission Date",
			filter: true,
			width: 210,
			isShowTime: true,
			locked: false,
			filterType: "date",
		},
		{ field: "approvalDate", title: "Approval Date", filterType: "date", isShowTime: true, filter: true, width: 210 },
		{
			field: "businessName",
			title: "Business Name/Personal Name",
			filter: true,
			width: 280,
		},
		{
			field: "referenceId",
			title: "Customer/Contract ID",
			filter: true,
			width: 200,
		},
		{
			field: "reasonForDeduction",
			title: "Reason For Deduction",
			filter: true,
			width: 250,
		},
		{
			field: "amountInUSD",
			title: "Amount in USD",
			filter: true,
			width: 180,
			dataType: "number",
			filterType: "numeric",
		},
		{ field: "currency", title: "Currency", filter: true, width: 130 },
		{
			field: "amountInCurrency", title: "Amount in Currency", filter: true, width: 180, dataType: "number",
			filterType: "numeric",
		},
		{
			field: "providerCost",
			title: "Provider Cost",
			filter: true,
			width: 200,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "profitInBase",
			title: "Profit in USD",
			filter: true,
			width: 180,
			dataType: "number",
			filterType: "numeric",
		},
		{ field: "createdBy", title: "Admin", filter: true, width: 130 },
		{ field: "state", title: "Status", filter: true, width: 140 ,
		customCell: (props) => {
			const statusColorConfig = this.props?.userConfig?.statusColors?.find(colorConfig => colorConfig.status === props.dataItem.state);
			const statusColor = statusColorConfig?.color.toLowerCase() || 'black';
			const textColor = (statusColor === 'red' || statusColor === 'darkgreen') ? 'white' : 'black';
	
			return (
				<td className="whitelist-name">
					<div className={`white-address ibancolor ${statusColor}`} style={{ color: textColor }}>
						{props.dataItem.state}
					</div>
				</td>
			);
		},},		{ field: "reasonForRejection", title: "Reason For Rejection", filter: true, width: 240 },
	];
   
    getStateData=async()=>{
        let res= await getCustomerState(this.state.selectedObj.state);
        if(res.ok){
        this.setState({...this.state,getstatesData:res.data,stateLoading:false});
        }else{
        this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(res),stateLoading:false});
        }
    }

    handleSelectionChange = (prop, e) => {
        this.formref.current?.resetFields();
        const rowObj = prop.dataItem;
        const value =
          e.target.type === "checkbox" ? e.target.checked : e.target.value;
        const name = e.target.name;
        let { selection } = this.state;
        let idx = selection.indexOf(rowObj.id);
        if (selection) {
          selection = [];
        }
        if (idx > -1) {
          selection.splice(idx, 1);
        } else {
          selection.push(rowObj.id);
        }
		this.props.getErrorMsg(null)
        this.setState({
          ...this.state,
          [name]: value,
          selectedObj: rowObj,
          selection,
          gridError: null,
          errorMsg:null,
          isbtnloading:false,
        });
      };
      statusChange=()=>{
		console.log(this.state.selectedObj,this.state.selectedState)
		this.setState({...this.state,selection:[],selectedObj:{},selectedState:null})

		this.formref?.current?.resetFields()
        if(this.state.selectedObj.state=="Approved"){
			this.props.getErrorMsg("This transaction is in approved status so you cant change state")
            this.setState({...this.state,selection:[],selectedObj:{}})
        }else if(this.state.selection.length==0){
			this.props.getErrorMsg("Please select the one record")
            this.setState({...this.state,selection:[],selectedObj:{}})
        }else{
            this.setState({...this.state, modal:true,stateLoading:true});
            this.getStateData();
            setTimeout(()=>{
                this.formref.current.setFieldsValue({...this.state, status: this.state?.selectedObj?.state,rejectReason: this.state?.selectedObj?.reasonForRejection});
            },1000)           
        }
      }

    handleStateChange=(e)=>{
     this.setState({...this.state,selectedState:e})
    }
    saveDeductionState=async(values)=>{
        this.setState({...this.state,isbtnloading:true,errorMsg:null})
        let obj = {  
            id : this.state.selectedObj.id,
            customerId : this.state.selectedObj.customerId,
            state : values.status,
            ModifiedBy : this.props?.userConfig?.userName,
            reasonForRejection : values.rejectReason,
           }
           let res = await saveCustomerState(obj);
           if(res.ok){
            this.setState({...this.state,modal:false,isbtnloading:false,selection:[],selectedObj:{}})
            message.success({
                content: "Record " + values.status + " successfully",
                className: "custom-msg",
                duration: 1
              })
              this.formref.current.setFieldsValue({status:"",rejectReason:""})
			  this.setState({...this.state,selection:[],selectedObj:{},selectedState:null})

              this.gridRef.current.refreshGrid()
           }else{
            this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(res),isbtnloading:false});
           }
    }
	deductionsDetailView = (e) => {
		this.props.getErrorMsg(null)
		this.props.dispatch(clearBreadcrumb());
		const val = e.dataItem.id;
		this.props.history.push("/deductions/detailView/" + val);
		this.props.dispatch(setBreadcrumb({
				key: "/deductions/detailView/" + val,
				val: e.dataItem.deductionTransactionId + " / " + e.dataItem.state,
			})
		);
	};
    handleCancel = (e) => {
		this.setState({
			...this.state,
			modal: false,
			selection: [],
            selectedState:null
		});
        this.formref.current?.resetFields();
	};
	isCheckStateChange=()=>{
		let stateChangePermissionCheck =this.props.permissions?.[this.props?.permissions?.currentScreenId]?.filter((item)=>(item.permissionName === "State Change")).map((item)=>(item.values))
		return stateChangePermissionCheck[0]
	  }
    render(){
        return(<>
            {<div className="text-right">
                <Button
                    type="primary"
                    className="primary-btn mb-16"
                    onClick={this.statusChange}>
                    State Change
                </Button>
            </div>}
                <List
                ref={this.gridRef}
				showActionBar={true}
				url={this.state.gridUrl}
				key={this.state.gridUrl}
				columns={this.gridColumns}
				showExcelExport={true}
				pKey={"Deductions"}
			/> 
            
            	<Modal
					title="Confirm Reject?"
					visible={this.state.modal}
					closeIcon={
						<Tooltip title="Close">
							<span
								className="icon md x c-pointer"
								onClick={this.handleCancel}
							/>
						</Tooltip>
					}
					footer={null}>
                        {this.state.errorMsg && (
					<Alert
						closable
						type="error"
						description={this.state.errorMsg}
						onClose={() => this.setState({...this.state,errorMsg:null})}
						showIcon
					/>
				)}
                {this.state.stateLoading && <Loader/>}
					<div>
						<Form
                         onFinish={this.saveDeductionState}
                         initialValues={this.state.selectedObj}
                         ref={this.formref}
							autoComplete="off">
                    
							<Row gutter={24} className="mb-24 pb-24 border-bottom">
									<Col xs={24} sm={24} md={24} xl={24} xxl={24}>
									<Form.Item
										name="status"
										label="State"
										className="input-label"
										rules={[
											{
												required: true,
												message: "Is required",
											},
										]}>
										<Select
                                            showSearch
											className="cust-input mb-0"
											onChange={(e) => this.handleStateChange(e)}
											placeholder="Select State"
                                            disabled={this.state.selectedObj.state=="Rejected"}
											>
												{this.state.getstatesData?.map(item => <Select.Option value={item?.name}>{item?.name}</Select.Option>)}
										</Select>
									</Form.Item>
								</Col>
								
                                {(this.state.selectedState=="Rejected" || this.state.selectedObj.state=="Rejected" )&&<Col xs={24}>
                                    <Form.Item
                                    name={"rejectReason"}
                                    label={<div>Reason For Rejection</div>}
                                    rules={[                       
                                        {
                                            required: true,
                                            message: "Is required",
                                            whitespace: true,
                                        },
                                        {
                                            validator: validateContentRule,
                                        }
                                    ]}
                                    className="input-label"
                                    >
                                    <TextArea
                                        disabled={
                                        this.state.selectedObj.state === "Rejected" 
                                        }
                                        customInput={Input}
                                        thousandSeparator={true}
                                        prefix={""}
                                        placeholder="Reason For Rejection"
                                        allowNegative={false}
                                        maxLength={100}
                                        rows={4}
                                        showCount
                                    />
                                    </Form.Item>
                                </Col>}
             
							</Row>
							<Form.Item className="mb-0">
								<div className="text-right">
									<Button
										type="primary"
										className="primary-btn cancel-btn mr-8"
										onClick={this.handleCancel}>
										Cancel
									</Button>
									{this.state.selectedObj.state !== "Rejected" &&
										<Button
											type="primary"
											key="submit"
											className="primary-btn"
											htmlType="submit"
                                            disabled={!this.state.selectedState}
                                            loading={this.state.isbtnloading}
											>
										      Save
										</Button>
									}
								</div>
							</Form.Item>
						</Form>
					</div>
				</Modal>
            </>)
    }

}
const connectStateToProps = ({ userConfig,permissions }) => {
	return {
		userConfig: userConfig.userProfileInfo,
		trackAuditLogData: userConfig.trackAuditLogData,permissions
	};
};
export default connect(
	connectStateToProps,
)(withRouter(DeductionGrid));