import { getDepositeCrypto, getCurrencyBankLu} from '../components/deposit.component/api'

//Actions
const FETCH_DEPOSIT = 'fetchDeposit';
const FETCH_DEPOSIT_SUCCESS = 'fetchDepositSuccess';
const FETCH_DEPOSIT_REJECT = 'fetchDepositReject';
const FETCH_CURRENCY = 'fetchCurrency';
const DEPOSIT_DETAILS = 'depositDetails';
const CRYPTO_DEPOSIT_DETAILS = 'cryptoDepositDetails';

//Action Type
const fetchDepositSuccess = (payload) => {
    return {
        type: FETCH_DEPOSIT_SUCCESS,
        payload
    }
}
const fetchDepositReject = (payload) => {
    return {
        type: FETCH_DEPOSIT_REJECT,
        payload
    }
}
const fetchCurrency = (payload) => {
    return {
        type: FETCH_CURRENCY,
        payload
    }
}
const depositDetails = (payload) => {
    return {
        type: DEPOSIT_DETAILS,
        payload
    }
}
const cryptoDepositDetails = (payload) => {
    return {
        type: CRYPTO_DEPOSIT_DETAILS,
        payload
    }
}
//InitialState
let initialState = {
    depositData: { loading: false, data: [], error: null },
    depositCurrency: [],
    depositDetails:{},
    cryptoDepositDetails:{}
}
const handleDepositViewData = (cryptoId) => {
    return async (dispatch) => {
        dispatch(fetchDepositSuccess({ key: "depositData", loading: true, data: [] }));
        const response = await getDepositeCrypto(cryptoId);
        if (response.ok) {
            dispatch(fetchDepositSuccess({ key: "depositData", loading: false, data: response.data, error: null }))
        } else {
            dispatch(fetchDepositReject({ key: "depositData", loading: false, data: [], 
            error: response.status === 401 ? (response.data.message) : response.originalError  }))
        }
    }
}

const handleCurrency = (bank) => {
    return async (dispatch) => {
        dispatch(fetchCurrency({ key: "depositCurrency", loading: true, data: [] }));
        const response = await getCurrencyBankLu(bank);
        if (response.ok) {
            dispatch(fetchCurrency({ key: "depositCurrency", loading: false, data: response.data, error: null }))
        } else {
            dispatch(fetchCurrency({ key: "depositCurrency", loading: false, data: [], error: response.originalError }))
        }
    }
}


//Reducer
const depositReducer = (state, action) => {
    if (!state) {
        state = {
          ...initialState,
          ...state
        }
      }
    switch (action.type) {
        case FETCH_DEPOSIT:
            return { ...state, isLoading: true }
        case FETCH_DEPOSIT_SUCCESS:
            return { ...state, [action.payload.key]: { data: action.payload.data, error: action.payload.error, loading: action.payload.loading } }
        case FETCH_DEPOSIT_REJECT:
            return { ...state, [action.payload.key]: { data: action.payload.data, error: action.payload.error, loading: action.payload.loading } }
        case FETCH_CURRENCY:
            return { ...state, [action.payload.key]: { data: action.payload.data, error: action.payload.error, loading: action.payload.loading } }
        case DEPOSIT_DETAILS:
                return { ...state, depositDetails: action.payload }
        case CRYPTO_DEPOSIT_DETAILS:
                return { ...state, cryptoDepositDetails: action.payload }
            default:
            return state;
    }

}

//Export
export default (depositReducer);
export { handleDepositViewData, handleCurrency,depositDetails,cryptoDepositDetails};