import { Modal, Tooltip, Form, Select, Input, Button, Alert, message, Col, Row, DatePicker } from "antd";
import React, { Component } from "react";
import { getStates, ApproveorCancelCrypto, stateFiatSave,getCustomerBankDetails } from "./api";
import { validateContentRule } from "../../utils/custom.validator";
import apiCalls from "../../api/apiCalls";
import { connect } from "react-redux";
import Loader from "../loader.component";
import NumberFormat from "react-number-format";
import moment from "moment";
import config from "../../config";
import { setCurrentAction } from "../../reducers/actionsReducer";
import { DYNAMIC_STATECHANGE_FIELDS } from './constants'
import { DYNAMIC_TEXT_FIELDS } from "../../utils/utilsconstants";
const { TextArea } = Input
class WithdrawStateChange extends Component {
  stateChangeForm;
  constructor (props) {
    super(props);
    this.state = {
      loading: false,
      statesLst: [],
      saveLoad: false,
      errorMessage: null,
      currentState: this.props?.selectedCustomer?.customerState,
      btnDisable: false,
      obj: {
        id: "",
        screenName: "Withdrawal",
        state: "",
        statusRemarks: "",
        rejectReason: "",
        bankTxnId: "",
        providerTxnId: "",
        transactionId: "",
        transactionrefId: "",
        isCheckBox: false,
        pcomission: null,
        pCommisionValue: null,
        sweepSourceCommisionValue: null,
        sweepDestinationCommissionValue: null,
        currency: "",
      },
      stateLoading: true,
      stateChange: {},
      isClickedStateChange: {},
      selectedBankName: null,
      isBtnApprove: false,
      btnDisabled: false,
      appwerningMsg: null,
      fiatBanks:[]

    }
    this.formref = React.createRef();

  }


  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      this.statusChange();
    }
  }
  bankChange = (data) => {
    this.setState({ ...this.state, selectedBankName: data })
  }

  validateBank = (rule, value) => {
    if (this.state.isClickedStateChange.status === `${DYNAMIC_STATECHANGE_FIELDS.SWEPT}` && !value) {
      return Promise.reject(new Error(`${DYNAMIC_TEXT_FIELDS.IS_REQUIRED}`));
    }
    return Promise.resolve();
  }

  getBankDetails = async (selectedCurrency) => {
    this.setState({ ...this.state, fiatBanks: null, appwerningMsg: null })
    let res = await getCustomerBankDetails(selectedCurrency, this.props.selectedObj.customerId,this?.props?.selectedObj?.bankAccountType =="Card" ? "Card" : " ")
    if (res.ok) {
      this.setState({ ...this.state, fiatBanks: res.data, appwerningMsg: null })
    } else {
      this.setState({ ...this.state, appwerningMsg: apiCalls.isErrorDispaly(res), fiatBanks: null, })
    }
  }
  statusChange = async () => {
    this.state.stateLoading = true
    let tabName = this.props.selectedTab === 1 ? `${DYNAMIC_STATECHANGE_FIELDS.SEND_FIAT}` : `${DYNAMIC_STATECHANGE_FIELDS.SEND_CRYPTO}`;
    if (this.props?.selection.length > 0) {
      this.setState({ ...this.state, stateLoading: true });
      this.getBankDetails(this.props?.selectedObj?.walletCode)

      let response = await getStates(tabName, this.props?.selectedObj?.status, this?.props?.selectedObj?.previousState)
      if (response.ok) {
        this.setState({
          ...this.state, statesLst: response.data, loading: false, appwerningMsg: null, btnDisabled: false,
          modal: true, stateLoading: false,
          warningMsg: null,
          error: null,
          stateChange: {
            status: this.props.selectedObj?.status,
            type: this.props.selectedObj?.type,
            statusRemarks: this.props.selectedObj?.statusRemarks,
            isCheckBox: this.props.selectedObj?.isCheckBox,
            transactionrefId: this.props.selectedObj?.transactionrefId,
            fiatBank: this.props.selectedObj.externalAccountName,
            pcomission: this.props.selectedObj?.pcomission,
            pCommisionValue: this.props.selectedObj?.pcomissionvalue,
            sweepDestinationCommissionValue: this.props.selectedObj?.sweepDestinationcomissionValue,
            rejectReason: this.props.selectedObj?.rejectReason,
            bankTxnId: this.props.selectedObj.bankTxnId,
            providerTxnId: this.props.selectedObj.providerTxnId,
            sweepSourceCommisionValue: this.props.selectedObj.sweepSourceComissionValue,
            currency: this.props.selectedObj.currency,
            previousState: this.props.selectedObj.previousState,
          }
        })
        this.formref?.current.setFieldsValue({
          ...this.state,
          status: this.props.selectedObj.status,
          statusRemarks: this.props.selectedObj.statusRemarks,
          isCheckBox: this.props.selectedObj.isCheckBox,
          fiatBank: this.props.selectedObj.externalAccountName,
          hash: this.props.selectedObj.hash,
          pcomission: this.props.selectedObj.pcomission,
          pCommisionValue: this.props.selectedObj.pcomissionvalue,
          sweepDestinationCommissionValue: this.props.selectedObj.sweepDestinationcomissionValue,
          rejectReason: this.props.selectedObj.rejectReason,
          bankTxnId: this.props.selectedObj.bankTxnId,
          providerTxnId: this.props.selectedObj.providerTxnId,
          sweepSourceCommisionValue: this.props.selectedObj.sweepSourceComissionValue,
          currency: this.props.selectedObj.currency,
          previousState: this.props.selectedObj.previousState,
          BankValueDate: this.props.selectedObj.bankValueDate && moment(new Date(this.props.selectedObj.bankValueDate), "DD/MM/YYYY"),
          transactionrefId: this.props.selectedObj.transactionrefId,
        })

      }
    }
  }



  handleRemarks = (e) => {
    const value = e.target.value;
    this.setState({
      ...this.state,
      isRemarks: value,
      btnDisabled: true
    });
  };


  renderRemarks() {
    if (![`${DYNAMIC_STATECHANGE_FIELDS.APPROVAL_IN_PROGRESS}`, `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`, `${DYNAMIC_STATECHANGE_FIELDS.REGISTERED}`, `${DYNAMIC_STATECHANGE_FIELDS.UNDER_REVIEW}`,].includes(this.state?.currentState?.toLowerCase())) {
      return <Form.Item className="input-label" name={`${DYNAMIC_STATECHANGE_FIELDS.REMARKS}`} label={this.state?.currentState === `${DYNAMIC_STATECHANGE_FIELDS.REJECTED}` ? `${DYNAMIC_STATECHANGE_FIELDS.REASON_FOR_REJECTION}` : `${DYNAMIC_STATECHANGE_FIELDS.REASON_FOR_CLOSE}`} rules={[{ required: true, message: `${DYNAMIC_TEXT_FIELDS.IS_REQUIRED}`, whitespace: true }, {
        validator: validateContentRule,
      },]}>
        <TextArea rows={3} className="" placeholder={this.state?.currentState === `${DYNAMIC_STATECHANGE_FIELDS.REJECTED}` ? `${DYNAMIC_STATECHANGE_FIELDS.REASON_FOR_REJECTION}` : `${DYNAMIC_STATECHANGE_FIELDS.REASON_FOR_CLOSE}`} maxLength={200} showCount disabled={this.props?.selectedCustomer?.customerState === `${DYNAMIC_STATECHANGE_FIELDS.REJECTED}` || this.props?.selectedCustomer?.customerState === "Closed"} />
      </Form.Item>
    }
  }
  getAppWarningMsg = () => (
    this.state.appwerningMsg !== undefined && this.state.appwerningMsg !== null && (
      <Alert
        className="mb-12"
        type="error"
        description={this.state.appwerningMsg}
        showIcon
      />
    )
  )
  isField1Disabled(disabledStatuses) {
    const { selectedObj } = this.props;
    return disabledStatuses.includes(selectedObj?.status);
  }
  validateNumber = (_, validNumberValue) => {
    if (validNumberValue === ".") {
      return Promise.reject(`${DYNAMIC_STATECHANGE_FIELDS.PLEASE_ENTER_VALID_CONTENT}`);
    }
    return Promise.resolve();
  }


  stateChange = (val, prop) => {

    if (val === `${DYNAMIC_STATECHANGE_FIELDS.REJECT}` || val === `${DYNAMIC_STATECHANGE_FIELDS.APPROVE}`) {
      this.formref.current.setFieldsValue({ statusRemarks: "", rejectReason: "", BankValueDate: "" })
    }
    this.setState({ ...this.state, btnDisabled: true, stateChange: { status: val }, isClickedStateChange: { status: val } });
    this.formref.current.setFieldsValue({ statusRemarks: "", rejectReason: "", BankValueDate: "" })
  };
  approveSave = async (values) => {
    this.setState({ ...this.state, isBtnApprove: true, appwerningMsg: null, isLoading: true })
    this.setState({ ...this.state, isLoading: true })
    let statusObj = this.state.obj;
    statusObj.ids = Array.isArray(this.props.selection)
      ? this.props.selection
      : [this.props.selection];
    statusObj.id = this.props.selectedObj.id;
    statusObj.AdminId = this.props.userConfig.id;
    statusObj.type = this.props.selectedTab === 1 ? `${DYNAMIC_STATECHANGE_FIELDS.FIAT}` : `${DYNAMIC_STATECHANGE_FIELDS.CRYPTO}`;
    statusObj.status = values.status || this.props.selectedObj.status;
    statusObj.statusRemarks = values.statusRemarks;
    statusObj.isCheckBox = values.status != `${DYNAMIC_STATECHANGE_FIELDS.PENDING}` ? this.props.selectedObj.isCheckBox : false;
    statusObj.hash = values?.hash;
    statusObj.ModifiedBy = this.props.userConfig.userName;
    statusObj.pCommisionValue = values.pCommisionValue;
    statusObj.sweepDestinationCommissionValue =
      values.sweepDestinationCommissionValue;
    statusObj.rejectReason =
      values.rejectReason;
    statusObj.sweepSourceCommisionValue =
      values.sweepSourceCommisionValue;
    statusObj.currency = this.props.selectedObj.currency || this.props.selectedObj.walletCode;
    statusObj.BankValueDate = values?.BankValueDate;
    statusObj.bankName = this.state?.selectedBankName || values?.fiatBank
    statusObj.info = JSON.stringify(this.props.trackAuditLogData);
    statusObj.transactionrefId = values?.transactionrefId;
    statusObj.bankTxnId = values.bankTxnId;
    statusObj.providerTxnId = values.providerTxnId;
    if (statusObj.type === `${DYNAMIC_STATECHANGE_FIELDS.CRYPTO}`) {
      if (this.props.selectedObj.isCheckBox || values.status == `${DYNAMIC_STATECHANGE_FIELDS.PENDING}` || `${DYNAMIC_STATECHANGE_FIELDS.SWEPT}`) {
        let response = await ApproveorCancelCrypto(statusObj);
        this.renderSuccessCommonData(response, values);
      }
    } else {
      let response = await stateFiatSave(statusObj);
      this.setState({ ...this.state, isClickedStateChange: {} })
      this.renderSuccessCommonData(response, values);
    }
  };
  renderSuccessCommonData = (response, values) => {
    if (response.ok) {
      this.props.dispatch(setCurrentAction(null));
      message.success({
        content: "Record " + (values.status || this.state.statusObj.status) + " successfully",
        className: "custom-msg",
        duration: 1
      });
      this.setState({
        ...this.state,

        error: null,
        stateLoading: true,
        btnDisabled: false,
        appwerningMsg: null,
        saveLoad: false, isBtnApprove: false,
      }, () => {

        this.props.onSuccess()

      });

    } else {
      this.setState({
        ...this.state,
        btnDisabled: false,
        saveLoad: false, isBtnApprove: false,
        appwerningMsg: apiCalls.isErrorDispaly(response),
        error: response.status === 401 ? response.data.message : response.data,
      });
    }
  }
  renderStatusError = () => {
    const { appwerningMsg } = this.state;
    return appwerningMsg !== undefined && appwerningMsg !== null && (
      <Alert
        className="mb-12 send-poperror"
        type="error"
        description={appwerningMsg}
        showIcon
      />
    )
  }

  renderCheckBox = () => {
    const {
      selectedObj,
      withdraw,
      stateChange
    } = this.state;
    return !withdraw && stateChange.status == `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}` && stateChange.status == `${DYNAMIC_STATECHANGE_FIELDS.REJECTED}` &&
      <div className="mb-8">
        <Alert message={<Form.Item
          name={`${DYNAMIC_STATECHANGE_FIELDS.IS_CHECKBOX}`}
          valuePropName={`${DYNAMIC_STATECHANGE_FIELDS.CHECKED}`}
          className="mb-0"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Please select checkbox')),
            },
          ]}
        ><div className="d-flex">
            <label className="text-center custom-checkbox mr-8">
              <input
                name={`${DYNAMIC_STATECHANGE_FIELDS.CHECK}`}
                type={`${DYNAMIC_STATECHANGE_FIELDS.CHECKBOX}`}
                checked={selectedObj.isCheckBox}
                onChange={(e) => this.handleImpNote(e)}
                disabled={selectedObj.status === `${DYNAMIC_STATECHANGE_FIELDS.REJECTED}` || selectedObj.status === `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}` || selectedObj.status?.indexOf(`${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`) > -1}
              />
              <span></span>
            </label>Important Note:</div></Form.Item>}
          description={<p className="fw-400 fs-14 mb-5 l-height-normal">Please ensure that transaction has been successfully completed prior to approval. Please click the checkbox above to confirm. </p>}
          type="info"
          className="imp-alert"
        /></div>
  }
  renderNetWorkField = () => {
    const { stateChange, selection, selectedObjs } = this.state;
    selectedObjs?.type != "Fiat" && stateChange.status == `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}` && stateChange.status == `${DYNAMIC_STATECHANGE_FIELDS.REJECTED}` && selection.length === 1 &&
      <Col xs={24} sm={24} md={12}>
        <Form.Item
          name={`${DYNAMIC_STATECHANGE_FIELDS.NETWORK}`}
          label={`${DYNAMIC_STATECHANGE_FIELDS.NETWORKS}`}
          className="input-label"
        >
          <Input placeholder={`${DYNAMIC_STATECHANGE_FIELDS.NET_WORK}`}
            disabled
            maxLength={150}
            className='cust-input' />
        </Form.Item>
      </Col>

  }
  onhandleClose=()=>{
    this.formref.current?.resetFields();
    this.props?.onClose()
}
  render() {
    const {  visible } = this.props;
    const decimalScaleValue = this.props.withdraw ? 2 : 8;

    return <Modal className="text-break"
      title={`${DYNAMIC_STATECHANGE_FIELDS.CONFIRM_APPROVE_REJECT}`}
      visible={visible}
      closeIcon={
        <Tooltip title="Close">
          <span className="icon md x c-pointer"
                    onClick={() => this.onhandleClose()}
                    />
        </Tooltip>
      }
      footer={null}
    >
      <div>
        {this.state.stateLoading && <Loader />}
        {this.renderStatusError()}
        <Form
          ref={this.formref}
          className="ant-advanced-search-form"
          autoComplete="off"
          onFinish={this.approveSave}
        >
          {this.renderCheckBox()}
          <Row gutter={24} className="mb-24 pb-24 border-bottom">
            {!this.props.withdraw && <Col xs={24}>
              <Form.Item
                name={`${DYNAMIC_STATECHANGE_FIELDS.HASH}`}
                label={`${DYNAMIC_STATECHANGE_FIELDS.HASHS}`}
                className="input-label"
                rules={[
                  {
                    required: this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`]),
                    message: `${DYNAMIC_TEXT_FIELDS.IS_REQUIRED}`,
                    whitespace: true
                  },
                  {
                    validator: validateContentRule
                  }
                ]}
              >
                <Input
                  placeholder={`${DYNAMIC_STATECHANGE_FIELDS.HASHS}`}
                  maxLength={150}
                  className='cust-input'
                  disabled={this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`, `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`, "Approved (Sent)"])}
                />
              </Form.Item>
            </Col>}
            {this.props.withdraw && <Col xs={24}>
              <Form.Item
                name={`${DYNAMIC_STATECHANGE_FIELDS.TRANSACTION_REFID}`}
                label={`${DYNAMIC_STATECHANGE_FIELDS.REFERENCE_ID}`}
                className="input-label"
                rules={[
                  {
                    required: this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`, "Approved (Sent)"]) || this.props?.status == `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`,
                    message: `${DYNAMIC_TEXT_FIELDS.IS_REQUIRED}`,
                    whitespace: true,
                  },
                  {
                    validator: validateContentRule,
                  },
                ]}>
                <Input
                  placeholder={`${DYNAMIC_STATECHANGE_FIELDS.REFERENCE_ID}`}
                  maxLength={20}
                  className="cust-input"
                  disabled={this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`, `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`, "Approved (Sent)"])}
                />
              </Form.Item>
            </Col>}
            {this.renderNetWorkField()}
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name={`${DYNAMIC_STATECHANGE_FIELDS.STATUS}`}
                label={`${DYNAMIC_STATECHANGE_FIELDS.STATE}`}
                className="input-label"
                rules={[
                  {
                    required: true,
                    message: `${DYNAMIC_TEXT_FIELDS.IS_REQUIRED}`
                  }
                ]}
              >
                <Select
                  className="cust-input mb-0"
                  onChange={(e) => this.stateChange(e)}
                  disabled={this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`, `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`, "Approved (Sent)"])}
                  placeholder={`${DYNAMIC_STATECHANGE_FIELDS.SELECT_STATE}`}
                >
                  {this.state.statesLst?.map(item => <Select.Option value={item?.code}>{item?.name}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name={`${DYNAMIC_STATECHANGE_FIELDS.PROVIDER_TXNID}`}
                label={`${DYNAMIC_STATECHANGE_FIELDS.KRAKEN_BINANCE}`}
                className="input-label"
                rules={[

                  {
                    validator: validateContentRule
                  }
                ]}

              >
                <Input
                  placeholder={`${DYNAMIC_STATECHANGE_FIELDS.KRAKEN_BINANCE}`}
                  className="cust-input"
                  maxLength={50}
                  disabled={this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`, `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`, "Approved (Sent)"])}

                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name={`${DYNAMIC_STATECHANGE_FIELDS.BANK_TXNID}`}
                label={`${DYNAMIC_STATECHANGE_FIELDS.BANK_TXN_ID}`}
                className="input-label"
                rules={[

                  {
                    validator: validateContentRule
                  }
                ]}

              >
                <Input
                  placeholder={`${DYNAMIC_STATECHANGE_FIELDS.BANK_TXN_ID}`}
                  className="cust-input"
                  maxLength={50}
                  disabled={this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`, `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`, "Approved (Sent)"])}

                />
              </Form.Item>
            </Col>
            {this.props.selectedObj?.previousState && <Col xs={24} sm={24} md={12}>
              <Form.Item
                name={`${DYNAMIC_STATECHANGE_FIELDS.PREVIOUSSTATE}`}
                label={`${DYNAMIC_STATECHANGE_FIELDS.PREVIOUS_STATE}`}
                className="input-label"
              >
                <Input placeholder={`${DYNAMIC_STATECHANGE_FIELDS.PREVIOUS_STATE}`}
                  disabled
                  maxLength={150}
                  className='cust-input' />
              </Form.Item>
            </Col>}
            {this.props.selectedTab == 1 && <>
              {(this.props.selectedObj?.bankAccountType !== "Personal" && this.props.selectedObj?.addressType !== "Internal Transfer") && (
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name={`${DYNAMIC_STATECHANGE_FIELDS.FIATBANK}`}
                    label={`${DYNAMIC_STATECHANGE_FIELDS.SENDERS_BANK_NAME}`}
                    className="input-label"
                    required
                    rules={[
                      {
                        validator: this.validateBank
                      }
                    ]}
                  >
                    <Select
                      className="cust-input mb-0"
                      onChange={(e) => this.bankChange(e)}
                      disabled={this.state.isClickedStateChange?.status !== `${DYNAMIC_STATECHANGE_FIELDS.SWEPT}`}
                      placeholder={`${DYNAMIC_STATECHANGE_FIELDS.SELECT_SENDERS_BANK_NAME}`}
                    >
                      {this.state.fiatBanks?.map(item => (
                        <Select.Option key={item?.bankName} value={item?.bankName}>
                          {item?.bankName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </>}
            {((this.state.stateChange.status == `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}` || this.state.stateChange.status == `${DYNAMIC_STATECHANGE_FIELDS.REJECTED}` || this.state.stateChange.status == "Approved (Sent)") &&
              <>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name={`${DYNAMIC_STATECHANGE_FIELDS.P_COMMISSION}`}
                    label={<div>Provider fees</div>}
                    rules={[
                      {
                        validator: this.validateNumber
                      }
                    ]}
                    className="input-label"
                  >
                    <NumberFormat
                      decimalScale={decimalScaleValue}
                      className="cust-input"
                      disabled={this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`, `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`, "Approved (Sent)"])}
                      customInput={Input}
                      thousandSeparator={true}
                      prefix={""}
                      placeholder={`${DYNAMIC_STATECHANGE_FIELDS.PROVIDER_FEES}`}
                      allowNegative={false}
                      maxLength={13}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name={`${DYNAMIC_STATECHANGE_FIELDS.SWEEP_SOURCE_COMMUNICATION}`}
                    label={<div>Sweep at source
                    </div>}
                    className="input-label"
                    rules={[
                      {
                        validator: this.validateNumber
                      }
                    ]}
                  >
                    <NumberFormat
                      decimalScale={decimalScaleValue}
                      className="cust-input"
                      disabled={this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`, `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`, "Approved (Sent)"])}
                      customInput={Input}
                      thousandSeparator={true}
                      prefix={""}
                      placeholder={`${DYNAMIC_STATECHANGE_FIELDS.SWEEP_AT_SOURCE}`}
                      allowNegative={false}
                      maxLength={13}
                    />
                  </Form.Item>
                </Col>
                <><Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name={`${DYNAMIC_STATECHANGE_FIELDS.SWEEP_DESTINATION_COMMUNICATION}`}
                    label={<div>Sweep at destination

                    </div>}
                    rules={[
                      {
                        validator: this.validateNumber
                      }
                    ]}
                    className="input-label"
                  >
                    <NumberFormat
                      decimalScale={decimalScaleValue}
                      className="cust-input"
                      disabled={this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`, `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`, "Approved (Sent)"])}
                      customInput={Input}
                      thousandSeparator={true}
                      prefix={""}
                      placeholder={`${DYNAMIC_STATECHANGE_FIELDS.SWEEP_DESTINATION}`}
                      allowNegative={false}
                      maxLength={13}
                    />
                  </Form.Item>
                </Col>
                  {this.state.stateChange.status !== `${DYNAMIC_STATECHANGE_FIELDS.REJECTED}` && (this.props.selectedTab === 1 || this.props.selectedTab == "Fiat") && <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name={`${DYNAMIC_STATECHANGE_FIELDS.BANK_VALUE_DATE}`}
                      className="input-label cust-datepicker-cursor"
                      label={`${DYNAMIC_STATECHANGE_FIELDS.BANK_VALUE}`}
                      rules={[
                        {
                          required: true,
                          message: `${DYNAMIC_TEXT_FIELDS.IS_REQUIRED}`
                        }
                      ]}
                    >
                      <DatePicker
                        format={config?.dateFormates?.dateFormate}
                        placeholder={`${DYNAMIC_STATECHANGE_FIELDS.BANK_VALUE}`}
                        className="cust-input"
                        disabled={this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.APPROVED}`, "Approved (Sent)"])}
                        disabledDate={(current) => current && current > moment().endOf('day')}
                      />
                    </Form.Item>
                  </Col>}
                </>
              </>)}
            {(this.state.stateChange.status === `${DYNAMIC_STATECHANGE_FIELDS.REJECTED}` && (
              <Col xs={24}>
                <Form.Item
                  name={`${DYNAMIC_STATECHANGE_FIELDS.REJECT_REASEON}`}
                  label={<div>Reason For Rejection

                  </div>}
                  className="input-label"
                  rules={[
                    {
                      required: true,
                      message: `${DYNAMIC_TEXT_FIELDS.IS_REQUIRED}`,
                      whitespace: true,
                    },
                    {
                      validator: this.validateNumber
                    }
                  ]}

                >
                  <TextArea
                    placeholder={`${DYNAMIC_STATECHANGE_FIELDS.REASON_FOR_REJECTION}`}
                    maxLength={100}
                    rows={4}
                    disabled={this.isField1Disabled([`${DYNAMIC_STATECHANGE_FIELDS.REJECTED}`])}
                    showCount
                  />
                </Form.Item>
              </Col>
            ))}
            <Col xs={24}>
              {(this.state.stateChange.status == `${DYNAMIC_STATECHANGE_FIELDS.APPROVED}` || this.state.stateChange.status == `${DYNAMIC_STATECHANGE_FIELDS.REJECTED}` || this.state.stateChange.status == "Approved (Sent)") && (
                <Form.Item
                  name={`${DYNAMIC_STATECHANGE_FIELDS.STATUS_REMARKS}`}
                  label={`${DYNAMIC_STATECHANGE_FIELDS.REMARKS}`}
                  className="input-label"
                  rules={[
                    {
                      whitespace: true,
                      message: `${DYNAMIC_TEXT_FIELDS.IS_REQUIRED}`
                    },
                    {
                      validator: validateContentRule
                    }
                  ]}
                >
                  <TextArea
                    placeholder={`${DYNAMIC_STATECHANGE_FIELDS.REMARKS}`}
                    maxLength={100}
                    rows={4}
                    value={this.state?.isRemarks}
                    showCount
                    onChange={(e) => this.handleRemarks(e)}
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
          <Form.Item className="mb-0">
            <div className="text-right">
              <Button
                type="primary"
                className="primary-btn cancel-btn mr-8"
                onClick={() => this.onhandleClose()}
                >
                Cancel
              </Button>
              <Button
                type="primary"
                key="submit"
                className="primary-btn"
                htmlType="submit"
                disabled={!this.state.btnDisabled}
                loading={this.state.isBtnApprove}
              >
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Modal>

  }
}

const connectStateToProps = ({ userConfig }) => {
  return {
    userConfig: userConfig.userProfileInfo,
    trackAuditLogData: userConfig.trackAuditLogData,
  };
};
export default connect(
  connectStateToProps,
)(WithdrawStateChange);