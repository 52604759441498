import React, { Component } from "react";
import { Card, Row, Col, Typography, Alert } from "antd";
import apiCalls from "../../api/apiCalls";
import { connect } from "react-redux";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { updateCurrentScreen } from "../../reducers/permissionsReducer";
const { Title, Paragraph } = Typography;

class BankReports extends Component {
  state = {
    reports: [],
    errorMsg: null
  };
  componentDidMount() {
    this.loadData();
    this.props.dispatch(updateCurrentScreen("openpaydReports"));
  }

  loadData = async () => {
    const screenName='openpayd'
    let response = await apiCalls.getBankreports(screenName);
    if (response.ok) {
      this.setState({ reports: response.data, errorMsg: null });
    } else {
      this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(response) })
    }
  };
  viewBankReport = (elem) => {
    this.props.history.push("/openpaydReports/" + elem.name);
    this.props.dispatch(
      setBreadcrumb({ key: "/Openpayd Reports/" + elem.name, val: "Report View" })
    );
  };
  render() {
    const { errorMsg } = this.state
    return (
      <>
        {errorMsg !== undefined && errorMsg !== null && (
          <Alert
            className="w-100 mb-16 align-center"
            type="warning"
            description={errorMsg}
            showIcon
          />
        )}
        <Row gutter={16}>
          {this.state.reports && (
            <>
              {this.state.reports.map((elem) => (
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}  key={elem?.id}>
                  <Card
                    className="db-card"
                    onClick={() => this.viewBankReport(elem)}
                  >
                    <div className="d-flex">
                      <span className="icon lg dashboard mr-16" />
                      <div style={{ flex: 1 }}>
                        <Title className="fs-20 fw-600 mb-0 text-white-30">
                          {elem.name}
                        </Title>
                        <Paragraph className="text-white-30 fs-14 fw-200 mb-0">
                          {elem.description}
                        </Paragraph>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </>
          )}
        </Row>
      </>
    );
  }
}
const connectStateToProps = ({ breadCrumb, oidc, userConfig }) => {
  return { breadCrumb, oidc, userConfig: userConfig.userProfileInfo };
};
export default connect(connectStateToProps, (dispatch) => {
  return { dispatch };
})(BankReports);
